@import './index.css';

@font-face {
    font-family: PoppinsBold;
    src: url(./assets/fonts/Poppins/Poppins-SemiBold.ttf);
}

body {
    margin: 0;
    padding: 0;
    font-family: 'Arial', sans-serif;
}

.section {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.header {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #fff;
    color: #000;
    height: 50px;
    font-size: 16px;
    font-weight: bold;
    position: fixed;
    width: 90%;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    transition: background-color 0.3s ease;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: 0px 6px 6px 0px rgba(0, 0, 0, 0.25);
    z-index: 100;
}

.header-option {
    cursor: pointer;
    padding: 10px;
}

.header-option:hover {
    background-color: var(--secondary-color);
}

.logo img {
    width: 3rem;
    cursor: pointer;
}

.login {
    background-color: var(--secondary-color);
    padding: 7px 15px;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none;
    color: black;
}

.buynow {
    /* background-color: var(--secondary-color); */
    padding: 7px 15px;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none;
    color: black;
}

#home {
    background: rgba(221, 247, 227, 0.94);
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 100vh;
    margin: auto;
    padding: 0 5rem;
    position: relative;
    text-align: center;
}

.home-content {
    width: 50%;
}

.home-content-title {
    color: #000;
    text-align: center;
    font-size: 40px;
    line-height: normal;
    font-family: "PoppinsBold", sans-serif;
}

.home-img img {
    width: 30rem;
    height: 28rem;
}

.home-content-body {
    margin-bottom: 40px;
    margin-top: 20px;
}

.home-bnt {
    display: flex;
    justify-content: center;
}

.btn-try {
    padding: 10px 15px;
    background-color: var(--primary-color);
    color: white;
    font-size: 16px;
    border-radius: 10px;
    margin-right: 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.btn-how-work {
    padding: 10px 15px;
    background-color: rgba(221, 247, 227, 0.94);
    color: black;
    font-size: 16px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    border: 1px solid #5D9C59;

    cursor: pointer;
}



.outstanding {
    color: #FFF;
    text-align: center;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.values {
    color: #DDF7E3;
    text-align: center;
    font-family: Poppins;
    font-size: 50px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    font-family: "PoppinsBold", sans-serif;
}

#function {
    background-color: var(--primary-color);
}

.function-cards {
    display: flex;
    margin: 0 7rem;
}

.function-card {
    text-align: center;
    padding: 40px 30px;
    background-color: white;
    margin: 30px;
    width: 90%;
}

.function-card-title {
    color: #000;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    margin: 20px 0;
}

.function-card-content {
    color: #000;
    text-align: center;
    font-size: 18px;
    font-style: nrmal;
    font-weight: 400;
    line-height: normal;
}

#aboutUs {
    background: rgba(221, 247, 227, 0.94);
}

.aboutUs-container {

    display: flex;
    justify-content: space-around;
    margin: 0 10rem;
}

.aboutUs-left img{
    width: 30rem;
    height: 30rem;
}

.aboutUs-right{
    width: 40%;
}
.aboutUs-right-introduce {
    color: #5D9C59;
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    
    font-family: "PoppinsBold", sans-serif;
}

.aboutUs-right-classy-app {
    color: #000;
    font-size: 40px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    
    font-family: "PoppinsBold", sans-serif;
}

.aboutUs-right-content .content{
    margin: 20px 0;
    font-size: 16px;
}

#configuration{
    background-color: white;
    height: auto;
}

.config-title{
    font-family: "PoppinsBold", sans-serif;
font-size: 30px;
font-style: normal;
font-weight: 600;
width: 60%;
text-align: center;
margin: 0 auto;
padding: 3rem 0;
}

.hl-1-container{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin: 0 10rem;
    background-color: var(--primary-color);
    padding: 2rem 5rem 3rem 5rem;
    border-radius: 20px;
    width: 80%;
}
.hl-1{
    color: #FFF;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: normal;
padding: 0 15px;
background-color: red;
width: fit-content;
margin: 20px 0;
}

.hl-1-title{
    color: #DDF7E3;
font-size: 20px;
font-style: normal;
font-weight: 800;
line-height: normal;
}

.hl-1-content{
    color: #FFF;
font-size: 16px;
font-weight: 400;
margin: 20px 0;
}

.hl-1-left{
    width: 50%;
    text-align: left;
    margin-right: 50px;
}

.hl-1-right img{
    width: 28rem;
    height: 28rem;
}

.hl-1-left .btn-try{
    background-color: red;
    width: fit-content;
}


.hl-2-container{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin: 5rem 10rem;
    background-color:#C5F4D0;
    padding: 03rem 5rem 3rem 5rem;
    border-radius: 20px;
    width: 80%;
}
.hl-2{
    color: #FFF;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: normal;
padding: 0 15px;
background-color: red;
width: fit-content;

}

.hl-2-title{
    color: #DDF7E3;
font-size: 20px;
font-style: normal;
font-weight: 800;
line-height: normal;
color: black;
}

.hl-2-content{
    color: black;
font-size: 16px;
font-weight: 400;
margin: 20px 0;
}


.hl-2-right{
    width: 60%;
    text-align: left;
    padding: 30px 40px;
    background-color: #fff;
    border-radius: 20px;
}

.hl-2-left img{
    width: 27rem;
    height: 30rem;
    margin-right: 40px;
}

.hl-2-right .btn-try{
    background-color: red;
    width: fit-content;
}

.config-container{
    width: 90%;
}


#price {
    background-color: white;
}
.price-container{
    text-align: center;
}

.price-cards{ display: flex;
width: 70%;
margin: 3rem auto;}

.price-card{
    padding: 30px;
    background-color: var(--secondary-color);
    margin: 10px;
    border-radius: 10px;
}

.price-plan{
    color: var(--primary-color);
    font-family: "PoppinsBold", sans-serif;
    font-size: 25px;
}

.sub-title{
    font-size: 14px;
}

.functions{
    text-align: left;
    margin: 30px 0;
}

.function{
    font-size: 14px;
}

.price-card .btn-try{
    width: fit-content;
    margin: 0 auto;
}

#contact{
    background-color: var(--primary-color)
    ;
    height: fit-content;
}

.contact-container{
    display: flex;
    color: white;
    align-items: center;
    justify-content: center;
}

.contact-container .btn-try{
    background-color: #FF5D5D;
    width: fit-content;
    margin: 20px 0;
}
.contact-right img{
    width: 30rem;
    margin-left: 3rem;
}

.contact-title{
    font-family: "PoppinsBold", sans-serif;
    color: var(--secondary-color);
    font-size: 25px;
}

.contact-left{
    width: 40%;
}

#footer{

    height: fit-content;
    padding: 30px;
    background-color: var(--secondary-color);
}

.footer-container{
    display: flex;
    justify-content: space-evenly;
    width: 100%;
}

.footer-title{
    font-family: "PoppinsBold", sans-serif;
    font-size: 20px;
    margin-bottom: 10px;
}