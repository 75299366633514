.dialog-ovelay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99999;
}
.dialog-ovelay .dialog {
  width: 400px;
  margin: 100px auto 0;
  background-color: #fff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  overflow: hidden;
}

.dialog-ovelay .dialog.custom {
  width: 80%;
  margin: 100px auto;
}

.dialog-ovelay .dialog header {
  padding: 10px 8px;
  background-color: #f6f7f9;
  border-bottom: 1px solid #e5e5e5;
}
.dialog-ovelay .dialog header h3 {
  font-size: 14px;
  margin: 0;
  color: #555;
  display: inline-block;
}
.dialog-ovelay .dialog header .fa-close {
  float: right;
  font-size: 1.5rem;
  color: #c4c5c7;
  cursor: pointer;
  transition: all 0.5s ease;
  padding: 0 2px;
  border-radius: 1px;
}
.dialog-ovelay .dialog header .fa-close:hover {
  color: #b9b9b9;
}
.dialog-ovelay .dialog header .fa-close:active {
  box-shadow: 0 0 5px #673ab7;
  color: #a2a2a2;
}
.dialog-ovelay .dialog .dialog-msg {
  padding: 12px 10px;
}
.dialog-ovelay .dialog .dialog-msg p {
  margin: 0;
  font-size: 15px;
  color: #333;
}
.dialog-ovelay .dialog footer {
  border-top: 1px solid #e5e5e5;
  padding: 8px 10px;
}
.dialog-ovelay .dialog footer .controls {
  direction: rtl;
}
.dialog-ovelay .dialog footer .controls .button {
  padding: 5px 15px;
  border-radius: 3px;
}
.button {
  cursor: pointer;
}
.button-false {
  background-color: rgb(248, 248, 248);
  border: 1px solid rgba(204, 204, 204, 0.5);
  color: #5d5d5d;
  margin-right: 10px;
}
.button-danger {
  background-color: #f44336;
  border: 1px solid #d32f2f;
  color: #f5f5f5;
}
.link {
  padding: 5px 10px;
  cursor: pointer;
}

.button-false:hover {
  background-color: #f3f3f3;
}

.button-danger:hover {
  background-color: #ff1302;
}
