.input-icon-container {
  display: flex;
  align-items: center;
}

.input-icon-container .fa {
  font-size: 1rem;
  position: relative;
  right: 2rem;
  color: rgba(0, 0, 0, 0.8);
}

.eye-button {
  border: none;
  cursor: pointer;
  background: none;
}
