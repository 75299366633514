.main-section {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 50px;
}

.profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  padding: 30px;
}

.profile .image {
  width: 100%;
  height: 100%;
  text-align: center;
}

/* Image Overlay */
.content-image-p {
  position: relative;
  width: 300px;
  max-width: 400px;
  margin: auto;
  cursor: pointer;
}

.content-image-p.userprofile {
  position: relative;
  width: 100%;
  max-width: 270px;
  margin: auto;
  cursor: pointer;
}

.content-image-p .content-overlay {
  background: rgba(0, 0, 0, 0.7);
  position: absolute;
  width: 300px;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  transition: all 0.4s ease-in-out 0s;
}

.content-image-p:hover .content-overlay {
  opacity: 1;
  border-radius: 10px;
}

.content-image {
  width: 300px;
  /*height: 300px; */
  border-radius: 10px;
}

.content-details {
  position: absolute;
  text-align: center;
  width: 300px;
  top: 50%;
  /* left: 50%; */
  opacity: 0;
  transform: translateY(-50%);
  transition: all 0.3s ease-in-out 0s;
}

.content-image-p:hover .content-details {
  top: 50%;
  /* left: 50%; */
  opacity: 1;
}

.content-details h3 {
  color: #fff;
  font-weight: 500;
  letter-spacing: 0.15em;
  margin-bottom: 0.5em;
  text-transform: uppercase;
}

.content-details p {
  color: #fff;
  font-size: 0.8em;
}

.fadeIn-bottom {
  top: 80%;
}

.profile .profile-name {
  font: bold 1.5rem "Roboto";
  text-transform: uppercase;
  color: #858796;
  margin-bottom: 10px;
}

.profile .role {
  color: #858796;
  font-size: 1.1rem;
}

.profile-info {
  background-color: white;
  padding: 10px 20px;
}

.profile-info__inner {
  border-bottom: 1px solid #e3e6f0;
  display: grid;
  grid-template-columns: 200px 1fr;
  font: normal 1.1rem "Poppins";
  color: #858796;
  padding: 10px;
}

/* The Modal (background) */
.model-section {
  display: none;
  position: fixed;
  z-index: 10001;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.8);
}

.model-section.visible {
  display: block;
}

.upload-image {
  text-align: center;
}

/* Modal Content */
.model-section .modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
}

/* The Close Button */
.model-section .close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.model-section .close:hover,
.model-section .close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.content h2 {
  margin-bottom: 20px;
}

.content .imageholder img {
  width: 250px;
  margin-bottom: 20px;
  border: 1px solid hsl(0, 0%, 0%, 0.4);
  /* padding-top: 20px; */
}

.content .label {
  margin-bottom: 20px;
  background-color: #e3e6f0;
  max-width: 20rem;
  line-height: 1.5;
  padding: 9px 20px 8px;
  margin-right: 20px;
}

.content .label:hover {
  cursor: pointer;
  background-color: #e3eaff;
}

.content #file-upload {
  display: none;
}

/* CSS */
.content button,
.btn-edit {
  background-color: #222;
  border-radius: 4px;
  border-style: none;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.5;
  width: 100px;
  padding: 9px 20px 8px;
}

.content button:hover,
.btn-edit:hover {
  opacity: 0.75;
}

.modal h2 {
  padding-bottom: 10px;
  border-bottom: 1px solid #bfc4d1;
}

.modal h3,
.h3 {
  margin: 20px 0;
  background-color: rgba(0, 0, 0, 0.8);
  color: #e3e6f0;
  padding: 10px 20px;
}

.custom-modal-input {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px;
}

.sort-icon {
  margin-left: 10px;
}
