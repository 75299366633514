@import './index.css';

@font-face {
  font-family: Poppins;
  src: url(./assets/fonts/Poppins/Poppins-Regular.ttf);
}

.horizontal-container {
  margin: 0 auto;
  width: 100%;
}
@media (min-width: 768px) {
  .horizontal-container {
    width: 400px;
  }
}

.row {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 100vh;
  margin: auto;
  background-color: white;
  padding: 0 10rem;
  position: relative;
}

.reset-message {
  color: #00529b;
  background-color: #bde5f8;
  width: auto !important;
  margin-top: 20px;
  padding: 10px;
  border-radius: 5px;
  font-size: 14px;
  margin-bottom: 20px;
  font-family: "Poppins", sans-serif;
}

/* Form CSS */
.horizontal-container .horizontal-form-box {
  background-color: #fff;
  border: 1px solid #e5e5e5;
  /* height: 466px; */
  padding: 30px;
  border: 1px rgba(0, 0, 0, 0.385) solid;  
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.219);
border-radius: 8px;

}
.horizontal-container .horizontal-info-container {
  text-align: center;
}

.horizontal-container .horizontal-info-container img {
  height: 75px;
  margin-bottom: 20px;
}
.horizontal-container .horizontal-heading {
  color: #000;
  font-size: 22px;
  font-weight: 600;
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
}
.horizontal-container .horizontal-subtitle {
  letter-spacing: 1px;
  margin-bottom: 20px;
  text-align: center;
  margin-top: 10px;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
}

.horizontal-form .o3-form-group {
  display: flex;
  flex-direction: column;
  font-family: sans-serif;
}

.horizontal-form .o3-form-group label {
  margin-bottom: 9px;
  font-family: "Poppins", sans-serif;
}

.horizontal-form .o3-form-group .custom_pass {
  margin-bottom: 20px;
}

.send__btn {
  width: 100%;
  margin: 20px auto;
  padding: 10px 0;
  font-size: 16px;
  color: white;
  background-color:#000000;
  cursor: pointer;
  border: none;
  border-radius: 10px;
  font-family: "Poppins", sans-serif;
}

.send__btn:hover {
  background-color:var(--primary-color);
}

.btn-custom-pass {
  width: 100%;
  margin: 20px auto;
  padding: 10px 0;
  font-size: 16px;
  color: white;
  background-color:#000000;
  cursor: pointer;
  border: none;
  border-radius: 10px;
  font-family: "Poppins", sans-serif;
}

.btn-custom:hover {
  background-color:var(--primary-color);
}

.back-login {
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
}