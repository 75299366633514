table {
  border-spacing: 1;
  border-collapse: collapse;
  background: #fff;
  border-radius: 6px;
  overflow: hidden;
  width: 100%;
  margin: 0 auto;
  position: relative;
}

table thead tr {
  background: #36304a;
}

table tbody tr {
  height: 50px;
}

tbody tr {
  font-family: "Open Sans";
  font-size: 15px;
  color: gray;
}

table td {
  text-align: left;
  padding: 15px;
}

table th {
  text-align: left;
  padding: 10px;
}

table th.secondary-heading {
  background-color: #aeaeae;
  padding: 4px 5px 7px;
}

thead th {
  font-family: "Open Sans";
  font-size: 18px;
  color: #fff;
  line-height: 1.2;
  font-weight: unset;
}

tbody tr:nth-child(even) {
  background-color: #f5f5f5;
}
table tbody tr:hover {
  color: #212529;
  background-color: #dee2e6;
  transition: background-color 0.5s;
}

.btn-primary {
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
}

.btn-primary:hover {
  background-color: #0066ff;
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
}

.btn-danger:hover {
  background-color: #ff0f27;
}

.btn-custom {
  padding: 4px 6px;
  font-size: 14px;
  border-radius: 5px;
  border: none;
  outline: none;
  cursor: pointer;
  margin: 5px;
}

.btn-1 {
  margin-right: 10px;
}

.table-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.select-entries span {
  margin: 0 10px;
  font-family: Arial, Helvetica, sans-serif;
}

.global-search span {
  font-family: Arial, Helvetica, sans-serif;
}

.global-filter {
  border: 1px solid #aaa;
  border-radius: 3px;
  padding: 4px 8px;
  background-color: transparent;
  margin-left: 3px;
  width: 150px;
  font-size: 0.7rem;
}

.select-entries {
  color: #333;
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-size: 90%/1.45rem;
}

.select-entries select {
  border: 1px solid #aaa;
  border-radius: 3px;
  padding: 5px;
  background-color: transparent;
  padding: 4px;
  width: 60px;
  font-size: 100%;
}

.bottom {
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  font-size: 90%/1.45rem;
  font-family: Arial, Helvetica, sans-serif;
}

.table-btn {
  /* border: none; */
  cursor: pointer;
  margin: 0 10px;
  padding: 4px 10px;
}

.table-btn:hover {
  background-color: #36304a;
  color: #dee2e6;
  font: 100%;
}

.input-page {
  border: 1px solid #aaa;
  border-radius: 3px;
  padding: 5px;
  background-color: transparent;
  padding: 4px;
  width: 60px;
  font-size: 100%;
}

.local-filter {
  width: 100%;
  max-width: 150px;
  border-radius: 4px;
  padding: 2px 6px;
}

.error404 td {
  text-align: center;
  font-size: 2rem;
  padding: 5rem;
  color: #dc3545;
}

.input-range {
  display: flex;
  max-width: 15rem;
}

.input-range span {
  text-align: center;
  margin: 0 10px;
}

.subject-column {
  text-align: center;
}

.detail-column {
  min-width: 100%;
}
