.content-section .custom-info-show {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 40px;
}

.information__info {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0 40px;
}

.profile-image .image {
  margin: 0 auto;
}

.profile-image .image img {
  width: 250px;
  border-radius: 50%;
  cursor: pointer;
  margin: 30px 0;
}

.profile-image .camera {
  font-size: 2rem;
  border: 2px solid #6764b3;
  border-radius: 50%;
  padding: 4px;
  position: relative;
  top: -55px;
  left: -55px;
  cursor: pointer;
  background-color: #242526;
  color: rgba(255, 255, 255, 1);
}
