/*
Consists Styling of
  => Main Container : Main Container for showing the details of components lie Dashboard COntent, Add Students Content
          => Consists InnerHeader and Main- conetent
  => Card Section
  => Input Field
*/
@import './components/teacherpanel/quiz/colors.css';
@import url('https://fonts.googleapis.com/css2?family=Nanum+Gothic:wght@400;700;800&display=swap');

.MuiToolbar-regular {
  padding-top: 25px !important;
  padding-bottom: 16px !important;
}

.MuiPaper-rounded {
  border-radius: 10px !important;
}

/* *******************************************Main Container Starts********************************************************* */
.main-container {
  margin-left: 15.3rem;
  transition: margin-left 0.5s;
  margin-top: 1.5rem;
}

.main-container .main-content {
  margin: 20px 40px 20px 40px;
}

.main-container.close {
  margin-left: 0rem;
  transition: margin-left 0.5s;
}

/* Inner Header CSS Code  */

.main-container .header {
  font: normal 16px "Poppins", sans-serif;
  padding-bottom: 10px;
  border-bottom: 0.7px solid #c8cbd9;
}

.main-container .header .IconName {
  margin-left: 40px;
  display: grid;
  /* width: 7.9rem; */
  grid-template-columns: 24px auto;
  grid-gap: 10px;
}

.main-container .header .IconName .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  color: white;
  background-color: #707fdd;
}

.main-container .header .reference {
  width: 72px;
  height: 8px;
  background: #27ae60;
  position: relative;
  top: 10px;
  left: 40px;
  z-index: 100;
}
/* Inner Header CSS Code  End*/

/* *******************************************Main Container Ends********************************************************* */

/* *******************************************Card Section Start********************************************************* */

/*Dashboard Element */

.main-content .cardelement {
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-gap: 20px;
}

.main-content .cardelement .cards {
  /* min-width: 15rem; */
  height: 9rem;
  background-color: white;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.cards .name-details .number {
  font: bold 35px "Open Sans", sans-serif;
  letter-spacing: 0.5px;
}

.cards .name-details .name {
  font: normal 18px "Open Sans", sans-serif;
  letter-spacing: 0.5px;
}

.cards .card-icons {
  font-weight: 900;
  font-size: 70px;
  display: flex;
  align-items: center;
}

.card-section {
  margin: 40px 0;
  font: normal 16px "Open Sans", sans-serif;
  /* width: 15rem; */
  /* width: fit-content; */
  padding-bottom: 30px;
  background-color: white;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
}

.card-section .heading,
.assignment_id {
  padding: 15px;
  border-bottom: 0.7px solid #c8cbd9;
  width: auto;
}

.card-section .heading .title-icon {
  margin-right: 10px;
  vertical-align: middle;
}

.card-section .heading .title {
  font-weight: bold;
}

.content-section {
  margin: 0 40px;
}

.content-section .message {
  color: #00529b;
  background-color: #bde5f8;
  width: auto !important;
  margin-top: 20px;
  padding: 10px;
  border-radius: 5px;
}

.content-section .mid-content,
.content-section.assignment_mid {
  margin-top: 30px;
}

.content-section .new-announcement {
  border-bottom: 0.7px solid #c8cbd9;
  padding-bottom: 20px;
  text-align: justify;
  text-justify: inter-word;
}

.custom-grid {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 8rem;
}

.morebutton {
  border-radius: 4px;
  border-style: none;
  text-align: center;
  vertical-align: middle;
  background-color: #222;
  padding: 10px;
  line-height: 1.5;
  margin-bottom: 10px;
  cursor: pointer;
}

.morebutton:hover {
  opacity: 0.75;
}

.btn-text {
  text-decoration: none;
  color: white;
  margin: 5px;
  width: 100%;
}

.btn {
  border: none;
  width: 6rem;
  color: white;
  font: normal 16px "Poppins", sans-serif;
}

.btn-custom-selection {
  margin-top: 20px;
  width: 10rem;
}

/**************************************Input Field Styling Starts**************************************/
/* Input Field Css Styling  */

.allinputfield {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 15px 40px;
}

.custom-announcementCreate {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0 5rem;
}

.modal-inputfield {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 15px 40px;
}

/* Settings CSS */
.inputfield {
  display: grid;
  grid-template-columns: 1fr 1.5fr;
  gap: 40px;
  margin-bottom: 20px;
}

.main-contentsection {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
}

.headerinputfield {
  justify-items: right;
}

/* Ends*/
.label-title {
  color: #81878c;
  margin-bottom: 10px;
}

.mid-icon {
  font-size: 20px;
  vertical-align: middle;
  margin-right: 5px;
  border: 1px solid #c8cbd9;
  border-radius: 50%;
  padding: 3px;
}

.mid-title {
  font: bold 14.5px "Open Sans", sans-serif;
  vertical-align: middle;
  letter-spacing: 0.5px;
}

.input {
  border: 1px solid #c8cbd9;
  box-sizing: border-box;
  padding: 7px 12px;
  width: 100%;
  border-radius: 4px;
  outline: none;
  line-height: 38px;
  font: normal 16px "Open Sans", sans-serif;
}

.input:focus {
  border: 2px solid #2684ff;
}

.input-select {
  width: 100%;
  margin-left: 5px;
  transition: width 0.5s;
}

input[type="file"] {
  color: #83889c;
}

.required {
  color: red;
  font-size: 20px;
}

.content-section.custom-content,
.custom-selection {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px 40px;
}

.textarea {
  resize: none;
}

.errors {
  color: #ba3939;
  font: bold 10.5px "Open Sans", sans-serif;
  background: #ffe0e0;
  border: 1px solid #a33a3a;
  padding: 2px 8px;
  margin: 30px 10px;
  vertical-align: middle;
}

.assignment_detail .assignment_id {
  color: #6e6e6e;
}

.grid_assignment {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.assignment_mid .info {
  display: grid;
  grid-template-columns: 10rem 1fr;
}

.assignment_mid .info h4 {
  color: #6e6e6e;
  padding: 10px;
}

.assignment_mid .info .content {
  border-left: 1px solid #c8cbd9;
  color: #6e6e6e;
  padding: 10px 0 10px 10px;
}

.instruction_info {
  border-top: 2px solid #c8cbd9;
  margin-top: 20px;
  padding-top: 20px;
  color: #6e6e6e;
}

.instruction_info .content {
  padding: 10px;
  margin-left: 10px;
  border-left: 1px solid #c8cbd9;
}

@media only screen and (max-width: 1350px) {
  .main-content .cardelement {
    grid-template-columns: repeat(2, auto);
  }

  .allinputfield {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }

  .modal-inputfield {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
}

@media only screen and (max-width: 1250px) {
  .main-content .cardelement {
    grid-template-columns: repeat(2, auto);
  }

  .main-contentsection {
    display: grid;
    grid-template-columns: 1fr;
    gap: 40px;
  }
}

@media only screen and (max-width: 1000px) {
  .modal-inputfield {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .allinputfield {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 850px) {
  .main-content .cardelement {
    grid-template-columns: repeat(1, auto);
  }

  .custom-content {
    grid-template-columns: repeat(1, auto);
  }

  .allinputfield,
  .content-section.custom-content,
  .modal-inputfield {
    display: grid;
    grid-template-columns: 1fr;
  }

  .custom-announcementCreate {
    grid-template-columns: 1fr;
  }
}

.App {
	text-align: center;
	align-items: center;
	overflow: hidden;
	color: var(--secondary);
	font-family: 'Nanum Gothic', sans-serif;
	/* background: var(--gradient); */
}
h1 {
	font-size: 3rem;
	font-weight: lighter;
}
h2 {
	font-size: 2rem;
}
p {
	font-size: 1rem;
}
.label {
	font-size: 1rem;
	/* padding:5px; */
}

.white {
	color: var(--primary);
}
.blue {
	color: var(--secondary);
}

.wd-200 {
	width: 200px;
}

input[type='checkbox'],
input[type='radio'] {
	transform: scale(1.5);
	-ms-transform: scale(1.5);
	-webkit-transform: scale(1.5);
}

/* input[type='text']  */
.input-text {
	border: var(--secondary) 1px solid;
	margin: 10px;
	padding: 10px;
	font-size: 16px;
	outline: none;
	border-radius: none;
	border: none;
	border-bottom: 2px solid var(--light-shadow);
}

/* input[type='text']  */
.input-text:focus {
	transition: all 0.5s ease;
	border-bottom: 2px solid var(--dark-shadow);
}

input[type='submit'],
.button {
	display: inline-block;
	margin: 10px;
	padding: 15px;
	font-size: 15px;
	text-transform: uppercase;
	transition: 0.5s;
	background-size: 200% auto;
	border-radius: 50px;
	border: none;
	background-color: #17a2b8;
	color: var(--primary);
}

input[type='submit'],
.button:hover {
	background-position: right center; /* change the direction of the change here */
	color: #fff;
	cursor: pointer;
	text-decoration: none;
}
input[type='submit'],
.button:focus {
	outline: none;
}
input[type='submit'],
.button:active {
	transform: translateY(4px);
	box-shadow: var(--dark-shadow) 2px 2px 3px;
}
.button .outlined {
	background-image: none;
	border: 2px solid;
	border-image: #17a2b8;
}

.questionCard {
	padding: 20px;
	box-shadow: var(--light-shadow) 1px 1px 1px 1px;
	margin: 20px 0px;
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	/* width:80%; */
  border: 1px solid black;
}
.attempQuestionCard {
	padding: 20px;
	box-shadow: var(--light-shadow) 1px 1px 1px 1px;
	margin: 10px 0px;
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	width: 90%;
}
.margin-top {
	margin-top: 50px;
	padding: 5%;
}
.response-table {
	width: 100%;
	border: 1px solid var(--secondary);
}
@media (min-width: 768px) {
	input[type='submit'] {
		width: 150px;
		padding: 10px;
	}

}

.App {
	text-align: center;
	align-items: center;
	overflow: hidden;
	color: var(--secondary);
	font-family: 'Nanum Gothic', sans-serif;
	/* background: var(--gradient); */
}
h1 {
	font-size: 3rem;
	font-weight: lighter;
}
h2 {
	font-size: 2rem;
}
p {
	font-size: 1rem;
}
.label {
	font-size: 1rem;
	/* padding:5px; */
}

.white {
	color: var(--primary);
}
.blue {
	color: var(--secondary);
}

.wd-200 {
	width: 200px;
}

input[type='checkbox'],
input[type='radio'] {
	transform: scale(1.5);
	-ms-transform: scale(1.5);
	-webkit-transform: scale(1.5);
}

/* input[type='text']  */
.input-text {
	border: var(--secondary) 1px solid;
	margin: 10px;
	padding: 10px;
	font-size: 16px;
	outline: none;
	border-radius: none;
	border: none;
	border-bottom: 2px solid var(--light-shadow);
}

/* input[type='text']  */
.input-text:focus {
	transition: all 0.5s ease;
	border-bottom: 2px solid var(--dark-shadow);
}

input[type='submit'],
.button {
	display: inline-block;
	margin: 10px;
	padding: 15px;
	font-size: 15px;
	text-transform: uppercase;
	transition: 0.5s;
	background-size: 200% auto;
	border-radius: 50px;
	border: none;
	background-color: #17a2b8;
	color: var(--primary);
}

input[type='submit'],
.button:hover {
	background-position: right center; /* change the direction of the change here */
	color: #fff;
	cursor: pointer;
	text-decoration: none;
}
input[type='submit'],
.button:focus {
	outline: none;
}
input[type='submit'],
.button:active {
	transform: translateY(4px);
	box-shadow: var(--dark-shadow) 2px 2px 3px;
}
.button .outlined {
	background-image: none;
	border: 2px solid;
	border-image: var(--gradient);
}

.questionCard {
	padding: 20px;
	box-shadow: var(--light-shadow) 1px 1px 1px 1px;
	margin: 20px 0px;
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	/* width:80%; */
}
.attempQuestionCard {
	padding: 20px;
	box-shadow: var(--light-shadow) 1px 1px 1px 1px;
	margin: 10px 0px;
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	width: 90%;
}
.margin-top {
	margin-top: 50px;
	padding: 5%;
}
.response-table {
	width: 100%;
	border: 1px solid var(--secondary);
}
@media (min-width: 768px) {
	input[type='submit'] {
		width: 150px;
		padding: 10px;
	}
	
}

