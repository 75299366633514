.container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin: auto;
    background-color: white;
    padding: 0 10rem;
    position: relative;
  }

  .container .image-container {
    text-align: center;
  }

  .campus-logo{
    height: 100px;
    margin: 20px 0;
  }

  .register-message{
    font-size: 18px;
    font-weight: bold;
    text-align: center;
   text-transform: uppercase
  }

  .message{
    text-align: center;
    margin-top: 15px;
  }

  .morebutton{
    margin: 0 auto;
    display: block;
    margin-bottom: 20px;
  }